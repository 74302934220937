<template>
  <div class="d-flex flex-column align-center fill-height">
    <v-card id="monetary" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        Donate
      </v-card-title>
      <v-divider />
      <v-card-text class="text-lg-subtitle-1 font-weight-bold">
        <p>
          BibleStudyTwo has ongoing expenses such as food, study materials,
          properties, staff, and special events. We are also raising funds for a
          permanent facility. If you would like to contribute to our ongoing
          expenses or contribute towards our new facility you can donate online
          via credit/debit or eCheck. Physical checks can be made payable to
          BibleStudyTwo and sent to P.O. Box 7534, Kingsport, TN 37664.
        </p>
        <div class="d-flex justify-center">
          <button
            class="aplos-donation-button"
            data-widget-id="80D2A492D07E67D73FC14830FE1D2139"
          >
            Click Here to Donate
          </button>
        </div>
        <p class="font-weight-bold black--text">
          BibleStudyTwo is a 501(c)(3) nonprofit Corporation. Your contribution
          is tax-deductible to the extent allowed by law.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Donate",
  // mounted() {
  //   global.createIframe();
  //   global.setupDonationButtons();
  //   global.setupMessageListeners();
  // },
};
</script>
